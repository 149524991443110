<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="900" scrollable>
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title
            class="black--text w-100 ta-c headline font-weight-bold"
            >แนวนโยบายและแนวปฏิบัติในการคุ้มครองข้อมูลส่วนบุคคล</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="subheading">
          <p><strong>1. นิยาม</strong></p>
          <p><strong>“กรม”</strong> หมายความว่า กรมป่าไม้</p>
          <p><strong>“บุคคล”</strong> หมายความว่า บุคคลธรรมดา</p>
          <p>
            <strong> “ข้อมูลส่วนบุคคล”</strong> หมายความว่า
            ข้อมูลเกี่ยวกับบุคคลซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้
            ไม่ว่าทางตรงหรือทางอ้อม แต่ไม่รวมถึงข้อมูลของผู้ถึงแก่กรรมโดยเฉพาะ
            เช่น ชื่อ นามสกุล ชื่อเล่น ที่อยู่ หมายเลขโทรศัพท์
            เลขประจำตัวประชาชน เลขหนังสือเดินทาง เลขบัตรประกันสังคม
            เลขใบอนุญาตขับขี่ เลขประจำตัวผู้เสียภาษี เลขบัญชีธนาคาร
            เลขบัตรเครดิต ที่อยู่อีเมล ทะเบียนรถยนต์ โฉนดที่ดิน IP Address,
            Cookie ID, Log File เป็นต้น อย่างไรก็ตาม
            ข้อมูลส่วนบุคคลจะไม่รวมถึงข้อมูลสำหรับการติดต่อทางธุรกิจที่ไม่ได้ระบุถึงตัวบุคคล
            เช่น ชื่อนิติบุคคล ที่อยู่ของนิติบุคคล เลขทะเบียนนิติบุคคล
            หมายเลขโทรศัพท์ของที่ทำงาน ที่อยู่อีเมลของนิติบุคคล
            ข้อมูลผู้ถึงแก่กรรม และข้อมูลนิรนาม (Anonymized Data)
            หรือข้อมูลแฝงที่ถูกทำให้ไม่สามารถระบุตัวบุคคลได้อีกโดยวิธีการทางเทคนิค
            เป็นต้น
          </p>
          <p>
            <strong> “ข้อมูลส่วนบุคคลอ่อนไหว”</strong> หมายความว่า
            ข้อมูลที่เป็นเรื่องส่วนบุคคลโดยแท้ของบุคคล
            แต่มีความละเอียดอ่อนและอาจทำให้เจ้าของข้อมูลส่วนบุคคล (Data Subject)
            นั้น มีความเสี่ยงต่อการถูกเลือกปฏิบัติอย่างไม่เป็นธรรม เช่น
            เชื้อชาติ เผ่าพันธุ์ ความคิดเห็นทางการเมือง ความเชื่อในลัทธิ
            ศาสนาหรือปรัชญา พฤติกรรมทางเพศ ประวัติอาชญากรรม ข้อมูลสุขภาพ
            ความพิการ ข้อมูลสหภาพแรงงาน ข้อมูลพันธุกรรม ข้อมูลภาพถ่ายของบุคคล
            และข้อมูลชีวภาพต่างๆ เป็นต้น
          </p>
          <p>
            <strong> “เจ้าของข้อมูลส่วนบุคคล </strong
            ><strong>(Data Subject)”</strong> หมายความว่า
            บุคคลที่ข้อมูลส่วนบุคคลนั้นบ่งชี้ไปถึงและทำให้สามารถระบุตัวบุคคลนั้นได้
            แต่ไม่ใช่บุคคลที่เป็นเจ้าของสิทธิในข้อมูลส่วนบุคคลหรือบุคคลที่เป็นผู้สร้างหรือเก็บรวบรวมข้อมูลส่วนบุคคลนั้น
            โดยเจ้าของข้อมูลส่วนบุคคลจะเป็นบุคคลธรรมดาเท่านั้น
            และไม่รวมถึงนิติบุคคล เช่น บริษัท สมาคม มูลนิธิ หรือองค์กรอื่นใด
            ทั้งนี้ เจ้าของข้อมูลส่วนบุคคล ได้แก่
          </p>
          <p>
            (1) เจ้าของข้อมูลส่วนบุคคลที่บรรลุนิติภาวะ หมายความว่า
            บุคคลที่มีอายุตั้งแต่ 20 ปีบริบูรณ์ขึ้นไป หรือผู้ที่สมรสตั้งแต่อายุ
            17 ปีบริบูรณ์ขึ้นไป หรือผู้ที่สมรสก่อนอายุ 17 ปี
            โดยศาลอนุญาตให้ทำการสมรสหรือผู้เยาว์ซึ่งผู้แทนโดยชอบธรรมให้ความยินยอมในการประกอบธุรกิจทางการค้าหรือธุรกิจอื่น
            หรือในการทำสัญญาเป็นลูกจ้างในสัญญาจ้างแรงงานในความเกี่ยวพันกับการประกอบธุรกิจหรือธุรกิจอื่นดังกล่าว
            ทำให้ผู้เยาว์มีฐานะเสมือนดังบุคคลซึ่งบรรลุนิติภาวะแล้ว
            โดยเจ้าของข้อมูลส่วนบุคคลที่บรรลุนิติภาวะนั้น สามารถให้ความยินยอมใดๆ
            ในการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลได้ด้วยตนเอง<br />
            (2) เจ้าของข้อมูลส่วนบุคคลที่เป็นผู้เยาว์ หมายความว่า
            บุคคลที่มีอายุต่ำกว่า 20 ปีบริบูรณ์
            และไม่ใช่เจ้าของข้อมูลส่วนบุคคลที่บรรลุนิติภาวะตาม (1)
            โดยการให้ความยินยอมใดๆ ในการเก็บรวบรวม ใช้
            หรือเปิดเผยข้อมูลส่วนบุคคล
            จะต้องได้รับความยินยอมจากผู้ใช้อำนาจปกครองที่มีอำนาจกระทำการแทนผู้เยาว์<br />
            (3) เจ้าของข้อมูลส่วนบุคคลที่เป็นคนเสมือนไร้ความสามารถ หมายความว่า
            บุคคลที่ศาลสั่งให้เป็นคนเสมือนไร้ความสามารถ
            เนื่องจากมีกายพิการหรือมีจิตฟั่นเฟือนไม่สมประกอบ
            หรือประพฤติสุรุ่ยสุร่าย เสเพล เป็นอาจิณ หรือติดสุรายาเมา
            หรือมีเหตุอื่นใดทำนองเดียวกันนั้น
            จนไม่สามารถจะจัดทำการงานโดยตนเองได้หรือจัดกิจการไปในทางที่อาจจะเสื่อมเสียแก่ทรัพย์สินของตนเองหรือครอบครัว
            โดยการให้ความยินยอมใดๆ ในการเก็บรวบรวม ใช้
            หรือเปิดเผยข้อมูลส่วนบุคคล
            จะต้องได้รับความยินยอมจากผู้พิทักษ์ที่มีอำนาจกระทำการแทนคนเสมือนไร้ความสามารถ<br />
            (4) เจ้าของข้อมูลส่วนบุคคลที่เป็นคนไร้ความสามารถ หมายความว่า
            บุคคลที่ศาลสั่งให้เป็นคนไร้ความสามารถ เนื่องจากเป็นบุคคลวิกลจริต
            โดยการให้ความยินยอมใดๆ ในการเก็บรวบรวม ใช้ หรือ
            เปิดเผยข้อมูลส่วนบุคคล
            จะต้องได้รับความยินยอมจากผู้อนุบาลที่มีอำนาจกระทำการแทนคนไร้ความสามารถ<br />
            การขอความยินยอมจากเจ้าของข้อมูลส่วนบุคคลที่ไม่เป็นไปตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
            ย่อมไม่มีผลผูกพันเจ้าของข้อมูลส่วนบุคคล
          </p>
          <p>
            <strong>“ผู้ควบคุมข้อมูลส่วนบุคคล”</strong> หมายความว่า
            บุคคลหรือนิติบุคคลซึ่งมีอำนาจหน้าที่ตัดสินใจเกี่ยวกับการเก็บรวบรวม
            ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล
            โดยกรมในฐานะที่เป็นหน่วยงานของรัฐซึ่งมีการเก็บรวบรวม ใช้
            หรือเปิดเผยข้อมูลส่วนบุคคลเพื่อการดำเนินงานตามภารกิจต่างๆ
            ถือได้ว่าเป็นผู้ควบคุมข้อมูลส่วนบุคคล
            ตามนัยของพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 ด้วย
          </p>
          <p>
            <strong>“ผู้ประมวลผลข้อมูลส่วนบุคคล”</strong> หมายความว่า
            บุคคลหรือนิติบุคคลซึ่งทำการประมวลผลข้อมูลส่วนบุคคลในนามหรือตามคำสั่งของผู้ควบคุมข้อมูลส่วนบุคคล
            ในกรณีที่กรมมีสัญญาจ้างบุคคลอื่นในการเก็บรวบรวม ใช้ ประมวลผล
            หรือเปิดเผยข้อมูลส่วนบุคคลให้แก่กรม
            จะถือว่าบุคคลหรือนิติบุคคลนั้นเป็นผู้ประมวลผลข้อมูลส่วนบุคคลให้แก่กรมตามนัยของพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
            พ.ศ. 2562
          </p>
          <p>
            <strong>“คุกกี้ </strong><strong>(Cookies)”</strong> หมายความว่า
            ข้อมูลอิเล็กทรอนิกส์เกี่ยวกับการเข้าถึงเว็บไซต์ของเจ้าของข้อมูลส่วนบุคคลที่เว็บไซต์เครื่องแม่ข่ายส่งไปเก็บไว้ในเครื่องคอมพิวเตอร์ที่เจ้าของข้อมูลส่วนบุคคลใช้ในการเข้าชมเว็บไซต์
            เพื่อช่วยให้เว็บไซต์สามารถจดจำข้อมูลการเข้าชมเว็บไซต์ของเจ้าของข้อมูลส่วนบุคคลได้
            และให้หมายความรวมถึงเทคโนโลยีอื่นที่มีลักษณะเดียวกับคุกกี้ (Cookies)
            ด้วย
          </p>
          <p><strong>2. นโยบายในการคุ้มครองข้อมูลส่วนบุคคล</strong></p>
          <p>
            <strong>
              2.1 การเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลอย่างจำกัด</strong
            >
          </p>
          <p>
            2.1.1 กรมเคารพในสิทธิและเสรีภาพของส่วนบุคคลตามกฎหมาย
            รวมถึงตระหนักถึงความสำคัญของการคุ้มครองข้อมูลส่วนบุคคลและความเป็นส่วนตัวของเจ้าของข้อมูลส่วนบุคคล
            ดังนั้น
            กรมจะปฏิบัติตามกฎหมายอย่างเคร่งครัดเมื่อกรมมีความจำเป็นต้องเก็บรวบรวม
            ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล ไม่ว่าในรูปแบบเอกสาร ฟิล์ม ภาพ
            หรือเสียง หรือข้อมูลอิเล็กทรอนิกส์
          </p>
          <p>
            2.1.2
            กรมจะใช้วิธีการที่ชอบด้วยกฎหมายและเป็นธรรมในการเก็บรวบรวมและเก็บรักษาข้อมูลส่วนบุคคล
            ทั้งที่เป็นการดำเนินการทางกายภาพและด้วยวิธีการทางอิเล็กทรอนิกส์
            โดยจะเก็บรวบรวม ใช้ หรือ
            เปิดเผยข้อมูลส่วนบุคคลอย่างจำกัดเพียงเท่าที่จำเป็นแก่การปฏิบัติตามภารกิจของกรม
            และภายใต้วัตถุประสงค์ที่กำหนดไว้ตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
            พ.ศ. 2562 เท่านั้น
          </p>
          <p>
            2.1.3 ในกรณีที่มีความจำเป็นต้องเก็บรวบรวมข้อมูลส่วนบุคคล
            กรมจะขอความยินยอมจากเจ้าของข้อมูลส่วนบุคคลก่อนหรือระหว่างการเก็บรวบรวมข้อมูลส่วนบุคคล
            เว้นแต่ในกรณีที่เป็นการเก็บรวบรวมข้อมูลส่วนบุคคลตามวัตถุประสงค์ดังต่อไปนี้
          </p>
          <p>
            (1) เพื่อการศึกษาวิจัยหรือสถิติ โดยมีมาตรการปกป้องที่เหมาะสม
            เพื่อคุ้มครองสิทธิและเสรีภาพของเจ้าของข้อมูลส่วนบุคคล เช่น
            การทำให้ข้อมูลส่วนบุคคลกลายเป็นข้อมูลนิรนาม เป็นต้น<br />
            (2) เพื่อป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย หรือสุขภาพของบุคคล
            ซึ่งเจ้าของข้อมูลส่วนบุคคลไม่สามารถให้ความยินยอมได้<br />
            (3)
            เป็นการจำเป็นเพื่อการปฏิบัติตามสัญญาซึ่งเจ้าของข้อมูลส่วนบุคคลเป็นคู่สัญญา
            หรือเพื่อใช้ในการดำเนินการตามคำขอของเจ้าของข้อมูลส่วนบุคคลก่อนเข้าทำสัญญานั้น
            เช่น
            การเก็บรวบรวมข้อมูลส่วนบุคคลนั้นเป็นเงื่อนไขส่วนหนึ่งของสัญญาจ้าง
            หรือเป็นเงื่อนไขของโครงการการจัดซื้อจัดจ้าง
            หรือเป็นเงื่อนไขของสัญญาการขอรับทุน เป็นต้น<br />
            (4) เป็นการจำเป็นเพื่อประโยชน์โดยชอบด้วยกฎหมายของกรม
            เว้นแต่ประโยชน์ดังกล่าว
            มีความสำคัญน้อยกว่าสิทธิขั้นพื้นฐานในข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคล<br />
            (5) เป็นการปฏิบัติตามกฎหมาย โดยกรมจะไม่เก็บข้อมูลส่วนบุคคลอ่อนไหว
            เช่น เชื้อชาติ เผ่าพันธุ์ ความคิดเห็นทางการเมือง ความเชื่อลัทธิ
            ศาสนาหรือปรัชญา พฤติกรรมทางเพศ ประวัติอาชญากรรม ข้อมูลสุขภาพ
            ความพิการ ข้อมูลสหภาพแรงงาน ข้อมูลพันธุกรรม และข้อมูลชีวภาพ
            โดยไม่ได้รับความยินยอมโดยชัดแจ้งจากเจ้าของข้อมูลส่วนบุคคล เว้นแต่<br />
            ก. เพื่อป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย หรือสุขภาพของบุคคล
            ซึ่งเจ้าของข้อมูลส่วนบุคคลไม่สามารถให้ความยินยอมได้
            ไม่ว่าด้วยเหตุใดก็ตาม<br />
            ข.
            เป็นการดำเนินกิจกรรมโดยชอบด้วยกฎหมายที่มีการคุ้มครองที่เหมาะสมของกรม
            ซึ่งเป็นองค์กรที่ไม่แสวงหากำไรตามวัตถุประสงค์
            โดยไม่ได้เปิดเผยข้อมูลส่วนบุคคลนั้นออกไปภายนอก<br />
            ค.
            เป็นข้อมูลที่เปิดเผยต่อสาธารณะด้วยความยินยอมโดยชัดแจ้งของเจ้าของข้อมูลส่วนบุคคล<br />
            ง. เพื่อประโยชน์สาธารณะด้านการสาธารณสุข เช่น
            การป้องกันด้านสุขภาพจากโรคติดต่ออันตรายหรือโรคระบาดที่อาจติดต่อหรือแพร่เข้ามาในราชอาณาจักร
            หรือการควบคุมมาตรฐานหรือคุณภาพของยา เวชภัณฑ์ หรือเครื่องมือแพทย์
            ซึ่งกรมได้จัดให้มีมาตรการที่เหมาะสมและเจาะจง
            เพื่อคุ้มครองสิทธิและเสรีภาพของเจ้าของข้อมูลส่วนบุคคล
            โดยเฉพาะการรักษาความลับของข้อมูลส่วนบุคคลตามหน้าที่และจริยธรรมแห่งวิชาชีพ<br />
            จ. เพื่อการศึกษาวิจัยทางวิทยาศาสตร์ หรือสถิติ
            หรือประโยชน์สาธารณะอื่น ทั้งนี้
            กรมจะกระทำเพื่อให้บรรลุวัตถุประสงค์ดังกล่าวเพียงเท่าที่จำเป็นเท่านั้น
            และได้จัดให้มีมาตรฐานที่เหมาะสม
            เพื่อคุ้มครองสิทธิขั้นพื้นฐานและประโยชน์ของเจ้าของข้อมูลส่วนบุคคลไว้ด้วยแล้ว<br />
            ฉ. เพื่อประโยชน์สาธารณะที่สำคัญ
            โดยกรมได้จัดให้มีมาตรการที่เหมาะสมเพื่อคุ้มครองสิทธิขั้นพื้นฐานและประโยชน์ของเจ้าของข้อมูลส่วนบุคคลไว้ด้วยแล้ว
          </p>
          <p>2.1.4 นโยบายด้านคุกกี้ (Cookies)</p>
          <p>
            (1) กรมและบุคคลอื่นอาจใช้เทคโนโลยีคุกกี้
            เพื่อเก็บรวบรวมข้อมูลพฤติกรรมของเจ้าของข้อมูลส่วนบุคคล
            เกี่ยวกับการเข้าถึง การใช้งาน
            หรือการรับบริการผ่านเว็บไซต์และแอปพลิเคชันของกรม
            เพื่อประโยชน์ในการอำนวยความสะดวกแก่เจ้าของข้อมูลส่วนบุคคล
            ในการเข้าถึง การใช้งาน หรือการ
            รับบริการผ่านเว็บไซต์และแอปพลิเคชันของกรม เช่น
            การบันทึกภาษาที่เลือกใช้ หรือการตั้งค่าอื่นๆ
            เมื่อเจ้าของข้อมูลส่วนบุคคลเข้าชมเว็บไซต์ในครั้งถัดไป
            โดยเว็บไซต์จะจดจำว่าเป็นผู้ที่เคยเข้าใช้บริการแล้ว
            และตั้งค่าเพื่อให้บริการตามที่เจ้าของข้อมูลส่วนบุคคลกำหนด ทั้งนี้
            จนกว่าเจ้าของข้อมูลส่วนบุคคลจะลบหรือยกเลิกการอนุญาตให้ใช้คุกกี้
            นอกจากนี้เจ้าของข้อมูลส่วนบุคคลสามารถยอมรับหรือไม่ยอมรับให้มีการใช้คุกกี้ก็ได้
            และในกรณีที่เลือกไม่ยอมรับให้มีการใช้คุกกี้
            เว็บไซต์ของกรมอาจจะไม่สามารถให้บริการหรือไม่สามารถแสดงผลในทุกกรณีได้อย่างสมบูรณ์<br />
            (2)
            กรมอาจใช้วิธีการอัตโนมัติในการอ่านหรือบันทึกข้อมูลลงในอุปกรณ์ของเจ้าของข้อมูลส่วนบุคคล
            ซึ่งรวมถึงคุกกี้ เว็บเบราว์เซอร์ หรือปลั๊กอินต่างๆ เช่น ที่เก็บ HTML
            หรือ ฐานข้อมูลแบบ Flash เป็นต้น
            โดยเจ้าของข้อมูลส่วนบุคคลอาจเลือกตั้งค่าเว็บเบราว์เซอร์ให้ปฏิเสธการให้จัดเก็บคุกกี้บางประเภท
            หรือให้แจ้งเตือนในกรณีที่มีการส่งคุกกี้บางประเภทได้
            ซึ่งเว็บเบราว์เซอร์บางตัวอาจถูกตั้งค่าที่คล้ายกันได้ อย่างไรก็ตาม
            ในกรณีที่เจ้าของข้อมูลส่วนบุคคลเลือกไม่ยอมรับให้มีการใช้คุกกี้
            เว็บไซต์หรือแอปพลิเคชันของกรมอาจไม่สามารถให้บริการหรือไม่สามารถแสดงผลในทุกกรณีได้อย่างสมบูรณ์<br />
            (3)
            ในการบริการจัดการความเป็นส่วนตัวเกี่ยวกับอุปกรณ์สื่อสารไร้สายของเจ้าของข้อมูลส่วนบุคคล
            จะเป็นไปตามคำแนะนำเกี่ยวกับวิธีบริหารจัดการความเป็นส่วนตัวตามที่ระบบปฏิบัติการของอุปกรณ์สื่อสารไร้สายนั้นๆ
            กำหนดไว้ เช่น
            การตั้งค่าความเป็นส่วนตัวสำหรับการระบุอุปกรณ์และตำแหน่งทางภูมิศาสตร์ได้ด้วยตนเองที่เว็บไซต์ของผู้ผลิตอุปกรณ์สื่อสารไร้สาย
            เป็นต้น
          </p>
          <p><strong> 2.2 คุณภาพของข้อมูลส่วนบุคคล</strong></p>
          <p>
            2.2.1 ข้อมูลส่วนบุคคลที่กรมอาจเก็บรวบรวม ใช้ ประมวลผล
            หรือเปิดเผยต่อบุคคลอื่น เช่น ชื่อ สกุล อายุ ที่อยู่ หมายเลขโทรศัพท์
            หมายเลขบัตรประชาชน ข้อมูลทางการเงิน
            จะดำเนินการเพื่อให้ข้อมูลส่วนบุคคลเหล่านั้นมีความถูกต้อง
            เป็นปัจจุบัน มีคุณภาพ
            และถูกนำไปใช้ให้เป็นไปตามวัตถุประสงค์ในการดำเนินงานของกรมเท่านั้น
            ทั้งนี้ กรมจะจัดให้มีกระบวนการจัดการข้อมูลส่วนบุคคลให้มีคุณภาพ
            เพื่อให้กระบวนการเก็บรวบรวม ใช้
            และเปิดเผยข้อมูลส่วนบุคคลเป็นไปอย่างถูกต้อง เป็นปัจจุบัน
            และมีความมั่นคงปลอดภัยตามมาตรฐานที่กฎหมายกำหนดหรือมาตรฐานสากล
          </p>
          <p>2.2.2 แหล่งที่มาของข้อมูลส่วนบุคคล</p>
          <p>
            (1)
            กรมจะเก็บรวบรวมข้อมูลส่วนบุคคลจากเจ้าของข้อมูลส่วนบุคคลโดยตรงเท่านั้น
            เว้นแต่เป็นการเก็บรวบรวมข้อมูลส่วนบุคคลที่กรมได้รับยกเว้น
            ไม่ต้องขอความยินยอมจากเจ้าของข้อมูลส่วนบุคคลตามกฎหมาย<br />
            (2)
            ในกรณีที่กรมมีเหตุผลความจำเป็นอย่างอื่นทำให้ต้องเก็บรวบรวมข้อมูลส่วนบุคคลจากแหล่งอื่นที่ไม่ใช่เจ้าของข้อมูลส่วนบุคคลโดยตรง
            กรมจะต้องแจ้งเหตุผลความจำเป็นที่ต้องเก็บรวบรวมข้อมูลส่วนบุคคลจากแหล่งอื่นที่ไม่ใช่เจ้าของข้อมูลส่วนบุคคลโดยตรงให้เจ้าของข้อมูลส่วนบุคคลทราบ
            เพื่อขอความยินยอมให้กรมดำเนินการดังกล่าวโดยเร็ว ทั้งนี้ ต้องไม่เกิน
            30 วัน นับแต่วันเริ่มดำเนินการเก็บรวบรวมข้อมูลส่วนบุคคล
            เว้นแต่การเก็บรวมรวมข้อมูลส่วนบุคคลในกรณีดังต่อไปนี้
            กรมไม่ต้องขอความยินยอมจากเจ้าของข้อมูลส่วนบุคคล<br />
            ก.
            เจ้าของข้อมูลส่วนบุคคลทราบวัตถุประสงค์และเหตุผลความจำเป็นที่ต้องเก็บรวบรวมข้อมูลส่วนบุคคลจากแหล่งอื่นที่ไม่ใช่เจ้าของข้อมูลส่วนบุคคลโดยตรงอยู่แล้ว<br />
            ข.
            กรมพิสูจน์ได้ว่าการเก็บรวบรวมข้อมูลส่วนบุคคลจากเจ้าของข้อมูลส่วนบุคคลโดยตรง
            ไม่สามารถกระทำได้หรือจะเป็นอุปสรรคต่อการเก็บรวบรวม ใช้
            หรือเปิดเผยข้อมูลส่วนบุคคล
            ให้บรรลุวัตถุประสงค์เกี่ยวกับการศึกษาวิจัยทางวิทยาศาสตร์
            ประวัติศาสตร์ หรือสถิติ และในกรณีนี้
            ได้จัดให้มีมาตรการที่เหมาะสมเพื่อคุ้มครองสิทธิของเจ้าของข้อมูลส่วนบุคคลด้วยแล้ว<br />
            ค. การเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล
            ต้องกระทำโดยเร่งด่วนตามที่กฎหมายกำหนด
            ซึ่งกรมได้จัดให้มีมาตรการที่เหมาะสมเพื่อคุ้มครองสิทธิของเจ้าของข้อมูลส่วนบุคคลด้วยแล้ว<br />
            ง.
            เมื่อกรมได้มาหรือรับรู้ซึ่งข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคลจากการปฏิบัติตามอำนาจหน้าที่ที่กฎหมายกำหนด
            และมีเหตุผลความจำเป็นบางประการในการเก็บ
            รวบรวมข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคล
            เพื่อนำไปใช้หรือเปิดเผยตามวัตถุประสงค์ เกี่ยวกับการจัดทำเอกสาร
            ประวัติศาสตร์หรือจดหมายเหตุ เพื่อประโยชน์สาธารณะ
            หรือเพื่อการศึกษาวิจัย สถิติ
            ซึ่งกรมได้จัดให้มีมาตรการที่เหมาะสมเพื่อคุ้มครองสิทธิของเจ้าของข้อมูลส่วนบุคคลด้วยแล้ว
            อย่างไรก็ตาม กรมจะแจ้งให้เจ้าของข้อมูลส่วนบุคคลทราบภายใน 30 วัน
            นับแต่วันที่เก็บรวบรวมข้อมูลส่วนบุคคลด้วยวิธีการดังกล่าวนี้<br />
            (3) กรมอาจทำการเก็บรวบรวมข้อมูลส่วนบุคคลจากเจ้าของข้อมูลส่วนบุคคล
            และนำไปผนวกเข้ากับข้อมูลส่วนบุคคลเกี่ยวกับเจ้าของข้อมูลส่วนบุคคลที่กรมได้รับมาจากแหล่งอื่น
            เฉพาะในกรณีที่มีความจำเป็นและได้รับความยินยอมจากเจ้าของข้อมูลส่วนบุคคลเท่านั้น
            ทั้งนี้ เพื่อประโยชน์ในการปรับปรุงข้อมูลส่วนบุคคลให้เป็นปัจจุบัน
            และเพื่อปรับปรุงคุณภาพ และประสิทธิภาพการให้บริการของกรมให้ดียิ่งขึ้น
          </p>

          <p>
            <strong>
              2.3 การระบุวัตถุประสงค์ในการเก็บรวบรวม ใช้
              และเปิดเผยข้อมูลส่วนบุคคล</strong
            >
          </p>

          <p>
            2.3.1 กรมจะเก็บรวบรวม ใช้
            และเปิดเผยข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคล
            เพื่อการดำเนินงานในพันธกิจต่างๆ ของกรม
            รวมทั้งเพื่อการศึกษาวิจัยหรือการจัดทำสถิติที่เป็นไปตามวัตถุประสงค์การดำเนินงานของกรมตามที่กฎหมายกำหนด
            และเพื่อการปรับปรุงคุณภาพการบริหารงานและการให้บริการของกรม
          </p>

          <p>
            2.3.2 ในกรณีที่มีการเปลี่ยนแปลงวัตถุประสงค์ในการเก็บรวบรวม ใช้
            และเปิดเผยข้อมูลส่วนบุคคล
            กรมจะแจ้งและขอความยินยอมจากเจ้าของข้อมูลส่วนบุคคลก่อน
            โดยกรมจะจัดให้มีการบันทึกความยินยอมไว้เป็นหลักฐาน
          </p>

          <p>
            2.3.3 ในกรณีที่กรมมีการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคล
            เพื่อดำเนินการอื่นนอกเหนือจากวัตถุประสงค์ตามที่กำหนดไว้ในข้อ 2.3.1
            และข้อ 2.3.2
            เจ้าของข้อมูลส่วนบุคคลมีสิทธิในฐานะที่เป็นเจ้าของข้อมูลส่วนบุคคลที่จะยินยอมหรือไม่ให้ความยินยอมให้กรมดำเนินการดังกล่าวก็ได้
          </p>

          <p>
            2.3.4 ในกรณีที่กรมมีการเก็บรวบรวมข้อมูลส่วนบุคคล
            เพื่อวัตถุประสงค์ในการวิเคราะห์และติดตามการใช้บริการทางเว็บไซต์
            และวัตถุประสงค์ในการตรวจสอบย้อนหลังในกรณีที่เกิดปัญหาการใช้งาน
            กรมจะจัดให้มีการบันทึกข้อมูลการเข้าออกเว็บไซต์ของเจ้าของข้อมูลส่วนบุคคลด้วย
            Log Files หรือด้วยเทคโนโลยีอื่นที่เหมาะสม
            โดยจัดเก็บข้อมูลอย่างน้อยดังต่อไปนี้
          </p>

          <p>
            (1) หมายเลขไอพี (IP Address)<br />
            (2) ประเภทของโปรแกรมเบราว์เซอร์ (Browser)<br />
            ทั้งนี้ กรมอาจใช้บริการหน่วยงานภายนอก
            เพื่อให้จัดเก็บบันทึกการเข้าออกระบบให้บริการทางเว็บไซต์ด้วยก็ได้
            อย่างไรก็ตาม กรมจะไม่กระทำการใดๆ
            ที่เกินขอบเขตตามวัตถุประสงค์ของการเก็บรวบรวม ใช้
            และเปิดเผยข้อมูลส่วนบุคคล
            เว้นแต่จะได้รับความยินยอมจากเจ้าของข้อมูลส่วนบุคคล
          </p>

          <p><strong> 2.4 ข้อจำกัดในการนำข้อมูลส่วนบุคคลไปใช้</strong></p>

          <p>
            2.4.1
            กรมจะใช้ประโยชน์หรือเปิดเผยข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคลได้ต่อเมื่อได้รับความยินยอมจากเจ้าของข้อมูลส่วนบุคคล
            และจะต้องเป็นการใช้ตามวัตถุประสงค์ของกรม
            หรือมีฐานทางกฎหมายอื่นรองรับเท่านั้น
          </p>

          <p>
            2.4.2
            กรมจะดูแลให้ผู้ปฏิบัติงานของกรมไม่นำข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคลไปใช้ประโยชน์
            หรือเปิดเผย แสดง
            หรือทำให้ปรากฏในลักษณะอื่นใดแก่บุคคลอื่นนอกเหนือจากวัตถุประสงค์ในการดำเนินงานตามภารกิจของกรม
            เว้นแต่
          </p>

          <p>
            (1)
            ได้รับความยินยอมให้เปิดเผยข้อมูลส่วนบุคคลจากเจ้าของข้อมูลส่วนบุคคล<br />
            (2) เพื่อให้บรรลุวัตถุประสงค์เกี่ยวกับการศึกษาวิจัยหรือสถิติ
            โดยมีมาตรการปกป้องข้อมูลส่วนบุคคลที่เหมาะสมสำหรับการคุ้มครองสิทธิและเสรีภาพของเจ้าของข้อมูลส่วนบุคคล
            เช่น การทำให้ข้อมูลส่วนบุคคลกลายเป็นข้อมูลนิรนาม เป็นต้น<br />
            (3) เพื่อป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย
            หรือสุขภาพของบุคคล<br />
            (4) เป็นการจำเป็นเพื่อการปฏิบัติตามสัญญา
            ซึ่งเจ้าของข้อมูลส่วนบุคคลเป็นคู่สัญญาหรือเพื่อดำเนินการตามคำขอของเจ้าของข้อมูลส่วนบุคคลก่อนเข้าทำสัญญานั้น
            เช่น
            การเก็บรวบรวมข้อมูลส่วนบุคคลนั้นเป็นเงื่อนไขส่วนหนึ่งของกระบวนการจัดซื้อจัดจ้างหรือการขอรับทุน<br />
            (5) เป็นการจำเป็นเพื่อประโยชน์โดยชอบด้วยกฎหมายของกรม
            เว้นแต่ประโยชน์ดังกล่าว
            มีความสำคัญน้อยกว่าสิทธิขั้นพื้นฐานในข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคล<br />
            (6)
            เป็นกรณีที่กรมต้องปฏิบัติให้เป็นไปตามกฎหมายหรือตามข้อกำหนดของผู้มีอำนาจสั่งการตามกฎหมาย
          </p>

          <p>
            2.4.3
            การเชื่อมโยงระบบสารสนเทศเพื่อเปิดเผยข้อมูลส่วนบุคคลแก่บุคคลภายนอก
          </p>

          <p>
            (1)
            ในกรณีที่กรมมีความจำเป็นต้องเชื่อมโยงระบบสารสนเทศเพื่อเปิดเผยข้อมูลส่วนบุคคลแก่บุคคลภายนอก
            กรมจะแจ้งและขอความยินยอมจากเจ้าของข้อมูลส่วนบุคคลก่อนดำเนินการ
            โดยมีรายละเอียดของข้อมูลที่จะแจ้งและขอคำยินยอมจากเจ้าของข้อมูลส่วนบุคคล
            อย่างน้อยต้องประกอบด้วย
          </p>

          <p>
            ก.
            บุคคลหรือหน่วยงานที่จะทำการเชื่อมโยงระบบสารสนเทศเพื่อเปิดเผยข้อมูลส่วนบุคคล<br />
            ข.
            วัตถุประสงค์ในการเชื่อมโยงระบบสารสนเทศเพื่อเปิดเผยข้อมูลส่วนบุคคล<br />
            ค. วิธีการเชื่อมโยงระบบสารสนเทศเพื่อเปิดเผยข้อมูลส่วนบุคคล<br />
            ง. ข้อมูลส่วนบุคคลที่จะเปิดเผยผ่านการเชื่อมโยงระบบสารสนเทศนั้น<br />
            จ. บุคคลซึ่งมีสิทธิเข้าถึงข้อมูลส่วนบุคคล
          </p>

          <p>
            (2)
            ในการเชื่อมโยงระบบสารสนเทศเพื่อเปิดเผยข้อมูลส่วนบุคคลให้แก่บุคคลภายนอก
            กรมจะแสดงชื่อผู้เก็บรวบรวมข้อมูลส่วนบุคคลและบุคคล
            ซึ่งมีสิทธิในการเข้าถึงข้อมูลส่วนบุคคลนั้นอย่างชัดเจน
            เพื่อให้เจ้าของข้อมูลส่วนบุคคลได้รับทราบ นอกจากนี้
            กรมจะจัดให้มีการบันทึกข้อมูลเกี่ยวกับการเชื่อมโยงระบบสารสนเทศเพื่อเปิดเผยข้อมูลส่วนบุคคลไว้เป็นหลักฐานด้วย<br />
            (3)
            ในกรณีที่มีการเปลี่ยนแปลงการเชื่อมโยงระบบสารสนเทศเพื่อเปิดเผยข้อมูลส่วนบุคคล
            กรมจะแจ้งให้เจ้าของข้อมูลส่วนบุคคลทราบถึงการเปลี่ยนแปลงดังกล่าว
            และขอความยินยอมก่อนการดำเนินการทุกครั้ง
          </p>

          <p>2.4.4 การประมวลผลข้อมูลส่วนบุคคล</p>

          <p>
            เมื่อกรมได้รับข้อมูลส่วนบุคคลจากแหล่งที่มาของข้อมูลส่วนบุคคลแล้ว
            กรมจะบริหารจัดการข้อมูลส่วนบุคคลภายใต้หลักการดังต่อไปนี้
          </p>

          <p>
            (1) ข้าราชการ ลูกจ้างประจำ พนักงานราชการ
            รวมทั้งผู้ที่เกี่ยวข้องกับข้อมูลส่วนบุคคลของกรมจะเก็บรวบรวม ใช้
            หรือเปิดเผยข้อมูลส่วนบุคคลตามคำสั่งอันชอบด้วยกฎหมายที่ได้รับจากผู้ควบคุมข้อมูลส่วนบุคคล
            หรือเจ้าพนักงานที่มีอำนาจหน้าที่ในการสั่งการตามกฎหมายเท่านั้น<br />
            (2)
            กรมจัดให้มีมาตรการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลที่เหมาะสม
            เพื่อป้องกันการสูญหาย เข้าถึง ใช้ เปลี่ยนแปลง หรือแก้ไข
            หรือเปิดเผยข้อมูลส่วนบุคคลโดยปราศจากอำนาจหรือโดยมิชอบด้วยกฎหมาย<br />
            (3) กรมจะจัดให้มีบันทึกรายการกิจกรรมการเก็บรวบรวม ใช้
            หรือเปิดเผยข้อมูลส่วนบุคคลไว้
            เพื่อให้สามารถตรวจสอบได้ตามหลักเกณฑ์และวิธีการที่กฎหมายกำหนด<br />
            (4) ในกรณีที่ผู้ประมวลผลข้อมูลส่วนบุคคลเป็นบุคคลภายนอก
            ผู้ประมวลผลข้อมูลส่วนบุคคลจะต้องปฏิบัติตามข้อกำหนดที่ได้รับแจ้งจากกรมเท่านั้น
            ทั้งนี้กรมจะจัดให้มีข้อตกลงระหว่างกรมกับผู้ประมวลผลข้อมูล
            เพื่อควบคุมการดำเนินงานตามหน้าที่ของผู้ประมวลผลข้อมูลส่วนบุคคลให้เป็นไปตามกฎหมาย
          </p>

          <p>
            <strong> 2.5 การรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคล</strong>
          </p>

          <p>
            2.5.1
            กรมตระหนักถึงความสำคัญของการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคล
            ดังนั้น
            กรมจึงกำหนดให้มีมาตรการในการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลอย่างเหมาะสม
            และสอดคล้องกับการรักษาความลับของข้อมูลส่วนบุคคลเพื่อป้องกันการสูญหาย
            การเข้าถึง ทำลาย ใช้ แปลง แก้ไข
            หรือเปิดเผยข้อมูลส่วนบุคคลโดยผู้ที่ไม่มีสิทธิหรือโดยไม่ชอบด้วยกฎหมาย
            ทั้งนี้
            เป็นไปตามแนวนโยบายและแนวปฏิบัติในการรักษาความมั่นคงปลอดภัยด้านเทคโนโลยีสารสนเทศที่กรมกำหนด
          </p>

          <p>
            2.5.2 ข้อมูลส่วนบุคคลที่กรมดำเนินการเก็บรวบรวม เช่น ชื่อ อายุ
            ที่อยู่ หมายเลขโทรศัพท์ หมายเลขบัตรประชาชน ข้อมูลทางการเงิน เป็นต้น
            ซึ่งเป็นข้อมูลที่สามารถบ่งบอกตัวบุคคลของเจ้าของข้อมูลส่วนบุคคลได้
            จะถูกนำไปใช้ให้เป็นไปตามวัตถุประสงค์การดำเนินงานของกรม
            ตามที่กฎหมายกำหนดเท่านั้น
            และกรมจะจัดให้มีมาตรการเพื่อคุ้มครองสิทธิของเจ้าของข้อมูลส่วนบุคคลที่เหมาะสมและสอดคล้องกับกฎหมายด้วย
          </p>

          <p>
            2.5.3 การเก็บรักษาและระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคล<br />
            กรมจะจัดให้มีระบบการตรวจสอบเพื่อดำเนินการลบหรือทำลายข้อมูลส่วนบุคคลเมื่อพ้นกำหนดระยะเวลาการเก็บรักษา
            หรือที่ไม่เกี่ยวข้อง
            หรือเกินความจำเป็นตามวัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคล
            หรือตามที่เจ้าของข้อมูลส่วนบุคคลร้องขอ
            หรือที่เจ้าของข้อมูลส่วนบุคคลได้เพิกถอนความยินยอม
            เว้นแต่การเก็บรักษาไว้เพื่อวัตถุประสงค์ในการใช้เสรีภาพในการแสดงความคิดเห็น
            หรือเพื่อให้บรรลุวัตถุประสงค์ที่เกี่ยวกับการจัดทำเอกสารประวัติศาสตร์หรือจดหมายเหตุ
            หรือเพื่อประโยชน์สาธารณะ หรือเพื่อการศึกษาวิจัยหรือสถิติ
            หรือเพื่อการปฏิบัติให้เป็นไปตามกฎหมาย
            ซึ่งกรมจะได้จัดให้มีมาตรการที่เหมาะสม
            เพื่อคุ้มครองสิทธิของเจ้าของข้อมูลส่วนบุคคลด้วย
          </p>

          <p>
            <strong>
              2.6 การเปิดเผยเกี่ยวกับการดำเนินการ แนวปฏิบัติ
              และนโยบายที่เกี่ยวกับข้อมูลส่วนบุคคล</strong
            >
          </p>

          <p>
            2.6.1 กรมจะเปิดเผยและเผยแพร่การดำเนินการ แนวปฏิบัติ
            และนโยบายเกี่ยวกับข้อมูลส่วนบุคคล
            รวมทั้งวัตถุประสงค์ของการนำข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคลที่เก็บ
            รวบรวม ใช้ หรือเปิดเผยผ่านทางเว็บไซต์ของกรม
            และผ่านสื่อที่ใช้เพื่อการประชาสัมพันธ์ตามความเหมาะสม นอกจากนี้
            กรมจะพิจารณาทบทวนการดำเนินการ แนวปฏิบัติ
            และนโยบายการคุ้มครองข้อมูลส่วนบุคคลเป็นระยะๆ
            เพื่อให้สอดคล้องกับกฎหมาย ข้อกำหนดและข้อบังคับที่เกี่ยวข้อง
            และในกรณีที่มีการเปลี่ยนแปลงการดำเนินการแนวปฏิบัติ
            และนโยบายการคุ้มครองข้อมูลส่วนบุคคลที่เป็นสาระสำคัญ
            กรมจะแจ้งให้เจ้าของข้อมูลส่วนบุคคล
            และผู้ใช้บริการทราบด้วยการจัดทำข้อมูลให้เป็นปัจจุบัน และเปิดเผย
            รวมทั้งเผยแพร่ผ่านทางเว็บไซต์ของกรม
            และผ่านสื่อที่กรมใช้เพื่อการประชาสัมพันธ์ตามความเหมาะสมด้วย
          </p>

          <p>
            2.6.2 การดำเนินการ แนวปฏิบัติ
            และนโยบายที่เกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลที่กรมประกาศใช้นี้
            จะใช้เฉพาะสำหรับการเก็บรวบรวม ใช้
            หรือเปิดเผยข้อมูลส่วนบุคคลในกิจการของกรม ซึ่งรวมตลอดถึงการบริหารงาน
            การให้บริการ และการเข้าถึงเว็บไซต์ของกรมเท่านั้น
            หากผู้ใช้บริการมีการเชื่อมโยงไปยังเว็บไซต์อื่นผ่านทางเว็บไซต์ของผู้ใช้บริการจะต้องศึกษาและปฏิบัติตามนโยบายและแนวปฏิบัติในการคุ้มครองข้อมูลส่วนบุคคลตามที่ปรากฏในเว็บไซต์อื่นนั้นแยกต่างหากจากกรมด้วย
          </p>

          <p>
            2.6.3 กรมจัดให้มีช่องทางการติดต่อสำหรับเจ้าของข้อมูลส่วนบุคคล
            และผู้ใช้บริการ ดังต่อไปนี้
          </p>

          <p>
            (1) ผู้ควบคุมข้อมูลส่วนบุคคล<br />
            หน่วยงาน : กรมป่าไม้<br />
            ที่อยู่ : 61 ถนนพหลโยธิน แขวงลาดยาว เขตจตุจักร กรุงเทพมหานคร
            10900<br />
            โทรศัพท์ : 0 2561 4292-3 ต่อ 5754 , 5621<br />
            Website :
            <a
              target="_blank"
              style="color: black"
              href="https://www.forest.go.th"
              >https://www.forest.go.th</a
            ><br />
            E-mail : fict@forest.go.th
          </p>

          <p>
            (2) เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (Data Protection Officer :
            DPO)<br />
            รองอธิบดีกรมป่าไม้ที่กำกับดูแลศูนย์เทคโนโลยีสารสนเทศและการสื่อสาร<br />
            ที่อยู่ : 61 ถนนพหลโยธิน แขวงลาดยาว เขตจตุจักร กรุงเทพมหานคร
            10900<br />
            โทรศัพท์ : 0 2561 4292-3 ต่อ 5754, 5621<br />
            E-mail : dpo@forest.go.th
          </p>

          <p>
            (3) ในกรณีที่บุคลากรผู้ปฏิบัติงานของกรม
            ฝ่าฝืนหรือไม่ปฏิบัติตามกฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล
            สามารถร้องเรียนต่อหน่วยงานกำกับดูแลตามรายละเอียดดังต่อไปนี้<br />
            หน่วยงาน : สำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล<br />
            สำนักงานปลัดกระทรวงดิจิทัลเพื่อเศรษฐกิจและสังคม<br />
            ที่อยู่ : ชั้น 7 อาคารรัฐประศาสนภักดี ศูนย์ราชการเฉลิมพระเกียรติ 80
            พรรษาฯ<br />
            ถนนแจ้งวัฒนะ แขวงทุ่งสองห้อง เขตหลักสี่ กรุงเทพมหานคร 10210<br />
            โทรศัพท์ : 0 2142 1033<br />
            E-Mail : pdpc@mdes.go.th
          </p>

          <p><strong> 2.7 การมีส่วนร่วมของเจ้าของข้อมูลส่วนบุคคล</strong></p>
          <p>
            2.7.1 กรมจะจัดช่องทางให้เจ้าของข้อมูลส่วนบุคคล
            สามารถตรวจสอบความมีอยู่และความถูกต้องของข้อมูลส่วนบุคคล
            รวมทั้งสามารถแก้ไขปรับปรุงข้อมูลส่วนบุคคลของตนเองได้ตามความเหมาะสม
            เช่น เว็บไซต์ของกรม บุคลากรผู้ปฏิบัติงานของกรม
            หรือช่องทางอื่นที่เหมาะสม
          </p>
          <p>2.7.2 เจ้าของข้อมูลส่วนบุคคล มีสิทธิในการดำเนินการดังต่อไปนี้</p>
          <p>
            (1) สิทธิในการเพิกถอนความยินยอม (Right to Withdraw Consent) :
            เจ้าของข้อมูลส่วนบุคคลมีสิทธิเพิกถอนความยินยอมในการประมวลผลข้อมูลส่วนบุคคลที่เจ้าของข้อมูลส่วนบุคคลเคยให้ไว้แก่กรมได้ตามที่กฎหมายกำหนด<br />
            (2) สิทธิในการเข้าถึงข้อมูลส่วนบุคคล (Right of Access) :
            เจ้าของข้อมูลส่วนบุคคลมีสิทธิในการเข้าถึงข้อมูลส่วนบุคคลของตนเอง
            และอาจขอให้กรมทำสำเนาข้อมูลส่วนบุคคลดังกล่าวให้แก่เจ้าของข้อมูลส่วนบุคคล
            รวมถึงขอให้กรมเปิดเผยการได้มาซึ่งข้อมูลส่วนบุคคลที่เจ้าของข้อมูลส่วนบุคคลไม่ได้ให้ความยินยอมต่อกรม<br />
            (3) สิทธิในการแก้ไขข้อมูลส่วนบุคคลให้ถูกต้อง (Right to
            Rectification) :
            เจ้าของข้อมูลส่วนบุคคลมีสิทธิขอให้กรมแก้ไขเปลี่ยนแปลงข้อมูลส่วนบุคคลที่ไม่ถูกต้องให้ถูกต้องได้
            หรือขอให้เพิ่มเติมข้อมูลส่วนบุคคลที่ไม่สมบูรณ์ให้สมบูรณ์ขึ้นได้<br />
            (4) สิทธิในการลบข้อมูลส่วนบุคคล (Right to Erasure) :
            เจ้าของข้อมูลส่วนบุคคลมีสิทธิขอให้กรมลบข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคลด้วยเหตุบางประการได้ตามที่กฎหมายกำหนด<br />
            (5) สิทธิในการระงับการใช้ข้อมูลส่วนบุคคล (Right to Restriction of
            Processing) :
            เจ้าของข้อมูลส่วนบุคคลมีสิทธิขอให้กรมจำกัดการใช้ข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคลด้วยเหตุบางประการได้ตามที่กฎหมายกำหนด<br />
            (6) สิทธิในการให้โอนย้ายข้อมูลส่วนบุคคล (Right to Data Portability)
            :
            เจ้าของข้อมูลส่วนบุคคลมีสิทธิในการโอนย้ายข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคลไปยังผู้ควบคุมข้อมูลรายอื่นหรือตัวเจ้าของข้อมูลส่วนบุคคลเองด้วยเหตุบางประการได้ตามที่กฎหมายกำหนด<br />
            (7) สิทธิในการคัดค้านการประมวลผลข้อมูลส่วนบุคคล (Right to Object) :
            เจ้าของข้อมูลส่วนบุคคลมีสิทธิคัดค้านการประมวลผลข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคลด้วยเหตุบางประการได้ตามที่กฎหมายกำหนด
          </p>
          <p>
            2.7.3 เจ้าของข้อมูลส่วนบุคคล
            สามารถติดต่อเจ้าหน้าที่ที่เป็นตัวแทนของกรมหรือที่กรมแต่งตั้งให้เป็นผู้คุ้มครองข้อมูลส่วนบุคคล
            (Data Protection Officer : DPO)
            เพื่อยื่นคำร้องขอให้ดำเนินการตามสิทธิของเจ้าของข้อมูลส่วนบุคคล
            ได้ตามที่กำหนดไว้ในข้อ 2.6.3 ทั้งนี้ จะจัดให้มีการบันทึกการใช้สิทธิ
            คำคัดค้านหรือการดำเนินการใดๆ
            ของเจ้าของข้อมูลส่วนบุคคลไว้เป็นหลักฐานด้วย
          </p>
          <p>
            2.7.4
            เจ้าของข้อมูลส่วนบุคคลสามารถศึกษาเพิ่มเติมเกี่ยวกับกฎหมายและแนวปฏิบัติการคุ้มครองข้อมูลส่วนบุคคล
            ได้จากเว็บไซต์ของกระทรวงดิจิทัลเพื่อเศรษฐกิจและสังคม (<a
              href="http://www.mdes.go.th"
              target="_blank"
              style="color: black"
              rel="noopener"
              >www.mdes.go.th</a
            >) และเอกสารแนวปฏิบัติเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล (<a
              href="https://www.law.chula.ac.th/wp-content/uploads/ 2020/12/TDPG3.0-C5-20201224.pdf"
              target="_blank"
              style="color: black"
              rel="noopener"
              >https://www.law.chula.ac.th/wp-content/uploads/
              2020/12/TDPG3.0-C5-20201224.pdf</a
            >)
          </p>
          <p>
            2.7.5 เจ้าของข้อมูลส่วนบุคคลสามารถใช้สิทธิข้างต้นได้
            โดยกรมจะพิจารณาคำร้องขอและแจ้งผลการพิจารณาให้ทราบภายใน 30 วัน
            นับแต่วันที่กรมได้รับคำร้องขอดังกล่าว ทั้งนี้
            การเก็บค่าใช้จ่ายในการจัดทำเอกสารที่เพิ่มขึ้นให้เป็นไปตามระเบียบกรมป่าไม้ว่าด้วยข้อมูลข่าวสารของราชการ
            พ.ศ. 2565
          </p>
          <p>
            <strong>
              2.8
              ความรับผิดชอบของบุคคลซึ่งมีหน้าที่ควบคุมข้อมูลส่วนบุคคล</strong
            >
          </p>
          <p>
            2.8.1 กรมจะกำหนดให้หน่วยงานที่มีหน้าที่ในการเก็บรวบรวม ใช้
            หรือเปิดเผยข้อมูลส่วนบุคคลในกิจการของกรม
            รวมทั้งบุคคลที่ได้รับมอบหมายให้ปฏิบัติงานเกี่ยวข้องกับข้อมูลส่วนบุคคล
            และผู้ประมวลข้อมูลส่วนบุคคลปฏิบัติตามนโยบายและแนวปฏิบัติในการคุ้มครองข้อมูลส่วนบุคคลที่กำหนดไว้อย่างเคร่งครัด
          </p>
          <p>
            2.8.2 ในกรณีที่ผู้ประมวลผลข้อมูลส่วนบุคคลเป็นบุคคลภายนอก
            กรมจะจัดให้มีข้อตกลงระหว่างกรมกับผู้ประมวลผลข้อมูล
            เพื่อกำหนดให้ผู้ประมวลผลข้อมูลส่วนบุคคลปฏิบัติตามข้อกำหนดเกี่ยวกับการเก็บ
            รวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลตามที่กรมกำหนดด้วย
            โดยกรมจะจัดให้มีการควบคุมและตรวจสอบการดำเนินงานตามหน้าที่ของผู้ประมวลผลข้อมูลส่วนบุคคลให้เป็นไปตามกฎหมายและข้อตกลงดังกล่าวอย่างเคร่งครัด
          </p>

          <p style="text-align:right">กรมป่าไม้<br />กุมภาพันธ์ 2566</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" flat @click="dialog = false"
            >ตกลง</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
  },
};
</script>
