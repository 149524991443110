<template lang="html">
  <v-container grid-list-md pb-0 pt-0>
    <v-layout row wrap justify-center mb-3 v-if="!isCompany && !isStudentLoan">
      <div class="ta-c">
        <span class="title font-weight-bold">ลงทะเบียน</span>
      </div>
    </v-layout>
    <v-layout row wrap justify-center v-if="isCompany" mb-2>
      <div class="ta-c">
        <span class="title font-weight-bold"
          >ลงทะเบียนกรณีหน่วยงานราชการ / องค์กร / บริษัท / อื่นๆ</span
        ><br />
      </div>
    </v-layout>

    <v-form ref="form">
      <v-layout row wrap justify-center>
        <v-flex md6 xs10 class="">
          <v-text-field
            :label="isCompany ? 'เลขทะเบียนนิติบุคคล*' : 'เลขบัตรประชาชน*'"
            prepend-inner-icon="payment"
            solo
            hide-details
            color="blue"
            v-model="card_id"
            :mask="mask"
            required
            type="tel"
            :rules="cardRules"
            @keyup="checkCardIdDebounce"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-center v-if="isCompany">
        <v-flex md6 xs10 class="">
          <v-text-field
            label="ชื่อหน่วยงาน/บริษัท/อื่นๆ*"
            prepend-inner-icon="business"
            solo
            hide-details
            color="blue"
            v-model="company"
            required
            :rules="nameRules"
            :disabled="isCheckCardID ? false : true"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-center v-if="!isCompany">
        <v-flex xs10 md6>
          <v-combobox
            :items="listTitle"
            label="พิมพ์หรือเลือกคำนำหน้าชื่อ*"
            solo
            :return-object="false"
            hide-details
            v-model="title"
            :rules="nameRules"
            :disabled="isCheckCardID ? false : true"
            required
            color="blue"
            item-text="title_name"
            prepend-inner-icon="person"
          ></v-combobox>
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-center v-if="!isCompany">
        <v-flex md3 xs10>
          <v-text-field
            label="ชื่อ*"
            prepend-inner-icon="person"
            solo
            hide-details
            color="blue"
            v-model="name"
            required
            :rules="nameRules"
            :disabled="isCheckCardID ? false : true"
          ></v-text-field>
        </v-flex>
        <v-flex md3 xs10>
          <v-text-field
            label="นามสกุล*"
            prepend-inner-icon="person"
            solo
            hide-details
            color="blue"
            v-model="surname"
            required
            :rules="nameRules"
            :disabled="isCheckCardID ? false : true"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-center>
        <v-flex md6 xs10>
          <v-text-field
            label="ที่อยู่*"
            prepend-inner-icon="home"
            solo
            hide-details
            color="blue"
            v-model="address"
            required
            :rules="nameRules"
            :disabled="isCheckCardID ? false : true"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-center>
        <v-flex md6 xs10>
          <v-text-field
            label="เบอร์โทรศัพท์"
            prepend-inner-icon="call"
            solo
            hide-details
            color="blue"
            v-model="phone"
            type="tel"
            :disabled="isCheckCardID ? false : true"
            :rules="phone !== '' ? phoneRules : []"
            ref="phone_ref"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-center>
        <v-flex md6 xs10>
          <v-text-field
            label="อีเมล"
            prepend-inner-icon="email"
            solo
            hide-details
            color="blue"
            v-model="email"
            :disabled="isCheckCardID ? false : true"
            :rules="email !== '' ? emailRules : []"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-center>
        <v-flex xs10 md6>
          <v-checkbox
            v-model="checkbox"
            hide-details
            required
            :disabled="isCheckCardID ? false : true"
            class="agreement"
          >
            <template v-slot:label class="grey--text font14"
              >ข้าพเจ้าทราบดีว่าการรวบรวมข้อมูลครั้งนี้เป็นไปด้วยความสมัครใจและยินยอมให้ใช้ข้อมูลของข้าพเจ้าตามที่ระบุไว้ใน
              <a
                href="#"
                @click.stop.prevent="openAgreement()"
                class="grey--text"
                >แนวนโยบายและแนวปฏิบัติในการคุ้มครองข้อมูลส่วนบุคคล</a
              >
            </template>
          </v-checkbox>
        </v-flex>
      </v-layout>
    </v-form>
    <v-layout row wrap justify-center mt-4>
      <v-flex md2 xs4>
        <div class="ta-c margin-checkbox">
          <v-btn
            color="primary"
            round
            block
            class="mx-0"
            @click="register()"
            :disabled="isCheckCardID ? false : true"
            >ลงทะเบียน</v-btn
          >
        </div>
      </v-flex>
    </v-layout>

    <v-layout row wrap justify-center mt-1>
      <div class="ta-c">
        <span
          class="subtitle-1 font-weight-bold ls-0 text-sm-body-2 text-md-display-1"
          >หากเคยลงทะเบียนแล้ว โปรด<span
            class="underline pointer"
            @click="gotoLogin()"
            >เข้าสู่ระบบ</span
          ></span
        >
      </div>
    </v-layout>
    <ModalAgreement ref="ModalAgreement" />
  </v-container>
</template>

<script>
import moment from "moment";
import ModalAgreement from "@/components/ModalAgreement";
export default {
  components: {
    ModalAgreement,
  },
  data() {
    return {
      disabled: true,
      items: ["นาย", "นาง", "นางสาว", "ด.ช.", "ด.ญ."],
      card_id: "",
      title: "",
      name: "",
      surname: "",
      address: "",
      phone: "",
      email: "",
      checkbox: false,
      mask: "#############",
      mask_phone: "#",
      date: "",
      nameRules: [(v) => !!v || "Name is required"],
      cardRules: [
        (v) => !!v || "Name is required",
        (v) => v.length == 13 || "Name must be less than 10 characters",
      ],
      isCheckCardID: false,
      checkCardIdDebounce: this.debounce(this.checkCardID, 500),
      emailRules: [
        (v) => /([^]).+@.+[^]+[.]+[^]/.test(v) || "E-mail must be valid",
      ],
      phoneRules: [(v) => /^[0-9]{9,10}$/.test(v) || "E-mail must be valid"],
      isCompany: false,
      isStudentLoan: false,
      company: "",
      listTitle: [],
      listCompany: [],
      company_other: "",
      academy: "",
      faculty: "",
      listAcademy: [],
      listFaculty: [],
      checkSchool: false,
    };
  },
  watch: {},
  created() {
    this.queryTitle();
    if (this.$route.path.split("/")[2] === "company") {
      this.isCompany = true;
    } else {
      this.isCompany = false;
    }
    // console.log(this.isCompany);
  },
  methods: {
    openAgreement() {
      this.$refs.ModalAgreement.openDialog();
    },
    moment() {
      this.date = moment().format();
    },
    gotoLogin() {
      this.$router.push("/login");
    },
    gotoPersonalRegister() {
      this.isCompany = false;
      this.$router.push("/register");
    },
    queryTitle() {
      this.axios
        .post(process.env.VUE_APP_IPFLASK + "/queryTitle")
        .then((response) => {
          this.listTitle = response.data;
        });
    },
    queryAcademy() {
      this.axios
        .post(process.env.VUE_APP_IPFLASK + "/queryAcademy")
        .then((response) => {
          this.listAcademy = response.data;
          // console.log(this.listAcademy);
        });
    },
    queryFaculty() {
      this.axios
        .post(process.env.VUE_APP_IPFLASK + "/queryFaculty")
        .then((response) => {
          this.listFaculty = response.data;
          // console.log(this.listFaculty);
        });
    },
    checkCardID() {
      if (this.card_id.length === 13) {
        var i = 0,
          sum = 0;
        for (i = 0; i < this.card_id.length - 1; i++) {
          sum += parseFloat(this.card_id[i]) * (13 - i);
        }
        if ((11 - (sum % 11)) % 10 === parseFloat(this.card_id[12])) {
          // console.log(this.card_id);
          this.axios
            .post(process.env.VUE_APP_IPFLASK + "/checkCardID", {
              card_id: this.card_id,
            })
            .then((response) => {
              // console.log(response.data);
              // console.log(this.isCompany);
              if (response.data === "You can use this Card id") {
                this.isCheckCardID = true;
              } else if (response.data === "Card id is already use") {
                if (this.isCompany) {
                  this.$swal(
                    "เลขทะเบียนนิติบุคคลนี้ถูกลงทะเบียนแล้ว",
                    "กรุณาลองใหม่อีกครั้ง",
                    "error"
                  );
                } else {
                  this.$swal(
                    "เลขบัตรประชาชนนี้ถูกลงทะเบียนแล้ว",
                    "กรุณาลองใหม่อีกครั้ง",
                    "error"
                  );
                }
                this.isCheckCardID = false;
              } else {
                // console.log(this.isCompany);
                if (this.isCompany) {
                  this.$swal(
                    "เลขทะเบียนนิติบุคคลไม่ถูกต้อง",
                    "กรุณาลองใหม่อีกครั้ง",
                    "error"
                  );
                } else {
                  this.$swal(
                    "เลขบัตรประชาชนไม่ถูกต้อง",
                    "กรุณาลองใหม่อีกครั้ง",
                    "error"
                  );
                }
                this.isCheckCardID = false;
              }
            });
        } else {
          this.isCheckCardID = false;
          if (this.isCompany) {
            this.$swal(
              "เลขทะเบียนนิติบุคคลไม่ถูกต้อง",
              "กรุณาลองใหม่อีกครั้ง",
              "error"
            );
          } else {
            this.$swal(
              "เลขบัตรประชาชนไม่ถูกต้อง",
              "กรุณาลองใหม่อีกครั้ง",
              "error"
            );
          }
        }
      } else {
        this.isCheckCardID = false;
      }
    },
    debounce(fn, time) {
      let timeout;
      return function() {
        const functionCall = () => fn.apply(this, arguments);
        clearTimeout(timeout);
        timeout = setTimeout(functionCall, time);
      };
    },
    register() {
      if (this.phone !== "") {
        if (!this.$refs.phone_ref.validate()) {
          this.$swal("กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง", "", "error");
          return;
        }
      }
      // console.log(this.company);
      if (this.$refs.form.validate()) {
        //check agreement
        if (!this.checkbox) {
          this.$swal(
            "กรุณายอมรับนโยบายการคุ้มครองข้อมูลส่วนบุคคล",
            "",
            "warning"
          );
          return;
        }

        if (this.isCompany) {
          this.name = "";
          this.surname = "";
          this.title = "";
        } else {
          this.company = "";
        }

        this.axios
          .post(process.env.VUE_APP_IPFLASK + "/register", {
            card_id: this.card_id,
            title: this.title,
            name: this.name,
            surname: this.surname,
            address: this.address,
            phone: this.phone,
            email: this.email,
            company: this.company,
          })
          .then((response) => {
            if (response.data === "Register Success") {
              // this.$swal('ลงทะเบียนเรียบร้อย','','success');
              this.$refs.form.reset();
              this.isCheckCardID = false;
              this.$swal({
                title: "ลงทะเบียนเรียบร้อย",
                text: "",
                type: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "OK",
                allowOutsideClick: false,
              }).then((result) => {
                if (result.value) {
                  this.$router.push("/login");
                }
              });
            } else {
              this.$swal("ลงทะเบียนไม่สำเร็จ", "กรุณาลองใหม่อีกครั้ง", "error");
            }
          });
      } else {
        this.$swal("กรุณากรอกข้อมูลให้ครบถ้วน", "", "error");
      }
    },
  },
};
</script>

<style scoped>
.font14 {
  font-size: 14px !important;
}

@media only screen and (max-width: 320px) {
  .margin-checkbox {
    margin-top: 100px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 600px) {
  .margin-checkbox {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 960px) {
  .margin-checkbox {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 961px) {
  .margin-checkbox {
    margin-top: 10px;
  }
}
</style>
