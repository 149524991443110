import { render, staticRenderFns } from "./ComponentRegister.vue?vue&type=template&id=397ef824&scoped=true&lang=html&"
import script from "./ComponentRegister.vue?vue&type=script&lang=js&"
export * from "./ComponentRegister.vue?vue&type=script&lang=js&"
import style0 from "./ComponentRegister.vue?vue&type=style&index=0&id=397ef824&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "397ef824",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/builds/rfd/seeding_72/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VCheckbox } from 'vuetify/lib'
import { VCombobox } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VForm } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
installComponents(component, {VBtn,VCheckbox,VCombobox,VContainer,VFlex,VForm,VLayout,VTextField})
