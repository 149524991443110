<template lang="html">
  <v-container grid-list-md align-center>
    <ComponentRegister/>
  </v-container>
</template>

<script>
import ComponentRegister from '@/components/ComponentRegister'
export default {
  components: {
    ComponentRegister
  },
  created(){
    this.isRegisterFormShow = false
    // if (this.$cookies.get('token') !== null){
    //   this.$router.push('/seeding')
    // }
  },
  data() {
    return{
      isRegisterFormShow: false,
      isLoginFormShow: true
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (vm.$cookies.get('token') !== null){
        next({path: '/seeding'});
      }
  })
  },
  methods: {
    isShowRegister(showRegister){
      this.isRegisterFormShow = showRegister
      this.isLoginFormShow = false
    },
    isShowLogin(showLogin){
      this.isLoginFormShow = showLogin
      this.isRegisterFormShow = false
    },
    getUsername(name_emit){
      this.$emit('getUsername', name_emit)
    }
  }
}
</script>
